<template>
<router-view></router-view>
<Footer />
</template>

<script>
import Footer from './components/footer-com.vue'

export default {
    components: {
        Footer,
    }
}
</script>

<style>
</style>
