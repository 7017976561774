<template>
    <div>
        <Navi />
        <div class="main res-py">
            <h1>TERMS AND CONDITIONS</h1><br>
            <h5>Welcome to Yathra Tours and Travels ("Yathra," "we," "our," or "us"). These Terms and Conditions govern your use of our tour services and website. By accessing our services or using our website, you agree to comply with these Terms and Conditions. Please read them carefully before proceeding with any booking or interaction with Yathra.</h5>
            <h3>Tour Bookings and Payments</h3>
            <ol type="a">
                <li>Booking Confirmation: To book a tour, you must provide accurate and complete personal information. The booking will be confirmed only after receiving the required payment.</li>
                <li>Payment: All tour prices are listed in the currency mentioned on the website or the invoice. You agree to pay the total amount specified at the time of booking, including any additional charges for optional activities or services.</li>
                <li>Cancellation and Refunds: Cancellation policies vary based on the tour package and timing of the cancellation. You agree to abide by the cancellation and refund policies outlined at the time of booking.</li>
            </ol>
            <h3>Traveler's Responsibilities</h3>
            <ol type="a">
                <li>Travel Documents: It is your responsibility to ensure that you possess valid passports, visas, and other required travel documents for the entire duration of the tour. Yathra will not be liable for any consequences arising from improper or incomplete documentation.</li>
                <li>Health and Safety: You must comply with any health and safety regulations provided by Yathra or its representatives during the tour. If you have any medical conditions or dietary requirements, you should inform us before booking to assess if we can accommodate them.</li>
                <li>Behavior: You agree to behave responsibly during the tour, respect local customs, and follow the instructions of Yathra's tour guides and representatives. We reserve the right to terminate your participation in the tour if your behavior is deemed disruptive or detrimental to the safety and enjoyment of others.</li>
                <li>Insurance: We strongly recommend obtaining comprehensive travel insurance that covers medical emergencies, trip cancellations, and personal belongings.</li>
                <li>Personal Accessories: We shall not take any responsibility for any of your person accessories. We strongly recommend the traveler to take care of their personal accessories e.g. Mobile Phones, Cameras, baggage’s, watches, wallets, etc.</li>
            </ol>
            <h3>Tour Changes and Cancellations</h3>
            <ol type="a">
                <li>Changes by Yathra: We strive to provide the tour itinerary as advertised. However, circumstances beyond our control may require itinerary changes. We reserve the right to modify or cancel tours if necessary. In such cases, we will provide suitable alternatives or refunds as applicable.</li>
                <li>Force Majeure: Yathra shall not be liable for any delays, cancellations, or modifications due to force majeure events, including but not limited to natural disasters, political unrest, strikes, or unforeseen operational constraints.</li>
            </ol>
            <h3>Website and Content</h3>
            <ol type="a">
                <li>Website Accuracy: We make every effort to ensure that the information on our website is accurate and up-to-date. However, we do not guarantee the accuracy, completeness, or reliability of the information and reserve the right to make changes without notice.</li>
                <li>Intellectual Property: The content on our website, including text, images, logos, and trademarks, is the property of Yathra or its licensors and is protected by copyright and other intellectual property laws. You may not reproduce, distribute, modify, or use any content without our written consent.</li>
            </ol>
            <h3>Limitation of Liability</h3>
            <ol type="a">
                <li>Yathra is not liable for any direct, indirect, incidental, consequential, or special damages arising from your use of our tour services or website.</li>
                <li>Yathra shall not be liable for any injuries, losses, damages, or expenses incurred during the tour, including but not limited to accidents, illness, theft, or property damage.</li>
            </ol>
            <h3>Indemnification</h3>
            <p>You agree to indemnify and hold Yathra, its directors, officers, employees, and agents harmless from any claims, demands, liabilities, expenses, or damages arising from your breach of these Terms and Conditions or your violation of any law or the rights of a third party.</p>
            <h3>Governing Law and Disputes</h3>
            <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Sri Lanka. Any disputes arising from the use of our services or website shall be subject to the exclusive jurisdiction of the courts of Sri Lanka.</p>
            <h3>Changes to the Terms and Conditions</h3>
            <p>Yathra may update these Terms and Conditions from time to time. The updated version will be posted on our website with the effective date. Your continued use of our services or website after the changes constitute your acceptance of the revised Terms and Conditions.</p>
            <h3>Contact Us</h3>
            <p>If you have any questions, concerns, or inquiries regarding these Terms and Conditions, please contact us at:</p>
            <p>Yathra Tours and Travels</p>
            <p>Address: Yathra Tours & Travels (PVT) Ltd, No 14/2, Rowan Place, Bandarawatha</p>
            <p>Email: info@yathratours.com</p>
            <p>Phone: +94771602526</p>
            <p>By booking a tour with us or using our website, you acknowledge that you have read and understood these Terms and Conditions and agree to be bound by them.</p>
            <br>
        </div>
    </div>
</template>

<script>
import Navi from '../components/navigation-com.vue'

export default {
    components: {
        Navi,
    }
}
</script>

<style scoped>
    h1{
        margin-top: 100px;
        text-align: center;
    }

    h3{
        margin-top: 50px;
    }

    h5{
        text-align: center;
    }

    .last-modified{
        text-align: end;
    }
</style>